import { EditorReadyFn } from '@wix/yoshi-flow-editor';
import type { WidgetInstallationType } from '@wix/platform-editor-sdk';

const presets = {
  activityShortList: {
    desktop: 'variants-l4h11t3a1',
  },
  activityFullList: {
    desktop: 'variants-l4i9aod1',
  },
  marketShortList: {
    desktop: 'variants-l4icfmdt',
  },
  stats: {
    desktop: 'variants-l4gxoa2j',
  },
};

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefId,
  options,
  flowAPI,
) => {
  if (options.firstInstall) {
    // TODO: Fix page layout
    await editorSDK.document.pages.add('', {
      title: flowAPI.translations.t('app.page.dashboard'),
      definition: {
        data: {
          pageUriSEO: 'dashboard',
          managingAppDefId: appDefId,
        },
        layoutResponsive: {
          id: 'string',
          type: 'LayoutData',
          metaData: {
            pageId: 'dashboard',
          },
          itemLayouts: [
            {
              id: 'bar',
              type: 'GridItemLayout',
              gridArea: {
                rowStart: 1,
                rowEnd: 2,
                columnStart: 1,
                columnEnd: 2,
              },
              alignSelf: 'center',
              justifySelf: 'center',
              margins: {
                top: {
                  type: 'percentage',
                  value: 0,
                },
                bottom: {
                  type: 'percentage',
                  value: 0,
                },
                left: {
                  type: 'percentage',
                  value: 0,
                },
                right: {
                  type: 'percentage',
                  value: 0,
                },
              },
            },
          ],
          containerLayouts: [
            {
              id: 'foobar',
              type: 'GridContainerLayout',
              rows: [
                {
                  type: 'fr',
                  value: 1,
                },
              ],
              columns: [
                {
                  type: 'fr',
                  value: 1,
                },
              ],
            },
          ],
          componentLayouts: [
            {
              id: 'foo',
              type: 'ComponentLayout',
              height: {
                type: 'px',
                value: 400,
              },
              width: {
                type: 'px',
                value: 980,
              },
              minWidth: {
                type: 'px',
                value: 980,
              },
              minHeight: {
                type: 'px',
                value: 400,
              },
              hidden: false,
            },
          ],
        },
        layout: {
          x: 0,
          y: 0,
          fixedPosition: false,
          width: 900,
          height: 800,
          scale: 1,
          rotationInDegrees: 0,
        },
      },
      shouldAddMenuItem: true,
      shouldNavigateToPage: true,
    });

    /**
     * Stats Widget (BOB)
     */
    await editorSDK.application.appStudioWidgets.addWidget('', {
      widgetId: 'dfed7631-a4b0-483f-8066-874ca4db4564',
      installationType: 'closed' as WidgetInstallationType,
      scopedPresets: {
        desktop: {
          layout: presets.stats.desktop,
          style: presets.stats.desktop,
        },
      },
      layout: {
        height: 406,
        width: 1200,
        x: 0,
        y: 115,
      },
      layouts: {
        componentLayout: {
          id: 'foo',
          type: 'ComponentLayout',
          height: {
            type: 'px',
            value: 400,
          },
          width: {
            type: 'px',
            value: 980,
          },
          minWidth: {
            type: 'px',
            value: 980,
          },
          minHeight: {
            type: 'px',
            value: 400,
          },
          hidden: false,
        },
        itemLayout: {
          id: 'bar',
          type: 'GridItemLayout',
          gridArea: {
            rowStart: 1,
            rowEnd: 1,
            columnStart: 1,
            columnEnd: 2,
          },
          alignSelf: 'center',
          justifySelf: 'center',
          margins: {
            top: {
              type: 'percentage',
              value: 0,
            },
            bottom: {
              type: 'percentage',
              value: 0,
            },
            left: {
              type: 'percentage',
              value: 0,
            },
            right: {
              type: 'percentage',
              value: 0,
            },
          },
        },
        containerLayout: {
          id: 'foobar',
          type: 'GridContainerLayout',
          rows: [
            {
              type: 'fr',
              value: 1,
            },
          ],
          columns: [
            {
              type: 'fr',
              value: 1,
            },
          ],
        },
      },
    });

    /**
     * Activity Short List Widget (BOB)
     */
    await editorSDK.application.appStudioWidgets.addWidget('', {
      widgetId: '4ab5d046-f73f-4feb-bf07-0a98f59d3194',
      installationType: 'closed' as WidgetInstallationType,
      scopedPresets: {
        desktop: {
          layout: presets.activityShortList.desktop,
          style: presets.activityShortList.desktop,
        },
      },
      layout: {
        height: 664,
        width: 1230,
        x: 0,
        y: 500,
      },
      layouts: {
        componentLayout: {
          id: 'foo',
          type: 'ComponentLayout',
          height: {
            type: 'px',
            value: 500,
          },
          width: {
            type: 'px',
            value: 980,
          },
          minHeight: {
            type: 'px',
            value: 500,
          },
          minWidth: {
            type: 'px',
            value: 980,
          },
          hidden: false,
        },
        itemLayout: {
          id: 'bar',
          type: 'GridItemLayout',
          gridArea: {
            rowStart: 2,
            rowEnd: 2,
            columnStart: 2,
            columnEnd: 2,
          },
          alignSelf: 'center',
          justifySelf: 'center',
          margins: {
            top: {
              type: 'percentage',
              value: 0,
            },
            bottom: {
              type: 'percentage',
              value: 0,
            },
            left: {
              type: 'percentage',
              value: 0,
            },
            right: {
              type: 'percentage',
              value: 0,
            },
          },
        },
        containerLayout: {
          id: 'foobar',
          type: 'GridContainerLayout',
          rows: [
            {
              type: 'fr',
              value: 1,
            },
          ],
          columns: [
            {
              type: 'fr',
              value: 1,
            },
          ],
        },
      },
    });

    /**
     * Market Short List Widget (BOB)
     */
    await editorSDK.application.appStudioWidgets.addWidget('', {
      widgetId: 'cd7ecce8-cf29-43ca-b9d8-5ee1eeb71dd4',
      installationType: 'closed' as WidgetInstallationType,
      scopedPresets: {
        desktop: {
          layout: presets.marketShortList.desktop,
          style: presets.marketShortList.desktop,
        },
      },
      layout: {
        height: 569,
        width: 1230,
        x: 0,
        y: 1000,
      },
      layouts: {
        componentLayout: {
          id: 'foo',
          type: 'ComponentLayout',
          height: {
            type: 'px',
            value: 500,
          },
          width: {
            type: 'px',
            value: 980,
          },
          minHeight: {
            type: 'px',
            value: 500,
          },
          minWidth: {
            type: 'px',
            value: 980,
          },
          hidden: false,
        },
        itemLayout: {
          id: 'bar',
          type: 'GridItemLayout',
          gridArea: {
            rowStart: 2,
            rowEnd: 2,
            columnStart: 2,
            columnEnd: 2,
          },
          alignSelf: 'center',
          justifySelf: 'center',
          margins: {
            top: {
              type: 'percentage',
              value: 0,
            },
            bottom: {
              type: 'percentage',
              value: 0,
            },
            left: {
              type: 'percentage',
              value: 0,
            },
            right: {
              type: 'percentage',
              value: 0,
            },
          },
        },
        containerLayout: {
          id: 'foobar',
          type: 'GridContainerLayout',
          rows: [
            {
              type: 'fr',
              value: 1,
            },
          ],
          columns: [
            {
              type: 'fr',
              value: 1,
            },
          ],
        },
      },
    });

    // TODO: Fix page layout
    // await editorSDK.document.pages.add('', {
    //   title: flowAPI.translations.t('app.page.activities'),
    //   definition: {
    //     data: {
    //       pageUriSEO: 'activities',
    //       managingAppDefId: appDefId,
    //     },
    //     layoutResponsive: {
    //       id: 'string',
    //       type: 'LayoutData',
    //       metaData: {
    //         pageId: 'activities',
    //       },
    //       itemLayouts: [
    //         {
    //           id: 'bar',
    //           type: 'GridItemLayout',
    //           gridArea: {
    //             rowStart: 1,
    //             rowEnd: 2,
    //             columnStart: 1,
    //             columnEnd: 2,
    //           },
    //           alignSelf: 'center',
    //           justifySelf: 'center',
    //           margins: {
    //             top: {
    //               type: 'percentage',
    //               value: 0,
    //             },
    //             bottom: {
    //               type: 'percentage',
    //               value: 0,
    //             },
    //             left: {
    //               type: 'percentage',
    //               value: 0,
    //             },
    //             right: {
    //               type: 'percentage',
    //               value: 0,
    //             },
    //           },
    //         },
    //       ],
    //       containerLayouts: [
    //         {
    //           id: 'foobar',
    //           type: 'GridContainerLayout',
    //           rows: [
    //             {
    //               type: 'fr',
    //               value: 1,
    //             },
    //           ],
    //           columns: [
    //             {
    //               type: 'fr',
    //               value: 1,
    //             },
    //           ],
    //         },
    //       ],
    //       componentLayouts: [
    //         {
    //           id: 'foo',
    //           type: 'ComponentLayout',
    //           height: {
    //             type: 'px',
    //             value: 400,
    //           },
    //           width: {
    //             type: 'px',
    //             value: 980,
    //           },
    //           minWidth: {
    //             type: 'px',
    //             value: 980,
    //           },
    //           minHeight: {
    //             type: 'px',
    //             value: 400,
    //           },
    //           hidden: false,
    //         },
    //       ],
    //     },
    //     layout: {
    //       x: 0,
    //       y: 0,
    //       fixedPosition: false,
    //       width: 900,
    //       height: 800,
    //       scale: 1,
    //       rotationInDegrees: 0,
    //     },
    //   },
    //   shouldAddMenuItem: true,
    //   shouldNavigateToPage: true,
    // });

    /**
     * Activity Full List Widget (BOB)
     */
    await editorSDK.application.appStudioWidgets.addWidget('', {
      widgetId: 'ca1e40b1-25e1-4762-b405-04c136beadf8',
      installationType: 'closed' as WidgetInstallationType,
      scopedPresets: {
        desktop: {
          layout: presets.activityFullList.desktop,
          style: presets.activityFullList.desktop,
        },
      },
      layout: {
        height: 569,
        width: 1230,
        x: 0,
        y: 150,
      },
      layouts: {
        componentLayout: {
          id: 'foo',
          type: 'ComponentLayout',
          height: {
            type: 'px',
            value: 500,
          },
          width: {
            type: 'px',
            value: 980,
          },
          minHeight: {
            type: 'px',
            value: 500,
          },
          minWidth: {
            type: 'px',
            value: 980,
          },
          hidden: false,
        },
        itemLayout: {
          id: 'bar',
          type: 'GridItemLayout',
          gridArea: {
            rowStart: 2,
            rowEnd: 2,
            columnStart: 2,
            columnEnd: 2,
          },
          alignSelf: 'center',
          justifySelf: 'center',
          margins: {
            top: {
              type: 'percentage',
              value: 0,
            },
            bottom: {
              type: 'percentage',
              value: 0,
            },
            left: {
              type: 'percentage',
              value: 0,
            },
            right: {
              type: 'percentage',
              value: 0,
            },
          },
        },
        containerLayout: {
          id: 'foobar',
          type: 'GridContainerLayout',
          rows: [
            {
              type: 'fr',
              value: 1,
            },
          ],
          columns: [
            {
              type: 'fr',
              value: 1,
            },
          ],
        },
      },
    });
  }
};
